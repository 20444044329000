import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import { Button, Container, Stack, Typography } from '@mui/material';
import { ArrowLeft } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';

const query = graphql`
  query ImpressumData {
    contentfulImpressum(id: { eq: "57e1dc0b-706d-5590-8f21-e28b7fa1d04f" }) {
      content {
        content
      }
    }
  }
`;

export default function Impressum() {
  const { contentfulImpressum } =
    useStaticQuery<Queries.ImpressumDataQuery>(query);
  return (
    <Container>
      <Stack spacing={6} alignItems="center">
        <Typography variant="h1" sx={{ textAlign: `center` }}>
          Impressum
        </Typography>
        <Typography>
          {contentfulImpressum?.content?.content && (
            <ReactMarkdown>
              {contentfulImpressum?.content?.content}
            </ReactMarkdown>
          )}
        </Typography>
        <Button
          component={GatsbyLink}
          to={`/`}
          variant="text"
          sx={{ width: 100, textTransform: `none` }}
          startIcon={<ArrowLeft />}
        >
          Zurück
        </Button>
      </Stack>
    </Container>
  );
}
